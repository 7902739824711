import React from "react";
import { Link } from "tsi-common-react/src/common/Link";
import { FinancingModalTrigger } from "tsi-common-react/src/apps/financing/FinancingModalTrigger";
import { urls } from "tsi-common-react/src/utils/urls";
import { format } from "tsi-common-react/src/utils/format";
import classNames from "classnames";
import {
    FinancingPlanMeta,
    getDefaultFinancingPlan,
} from "tsi-common-react/src/utils/financing";
import cocoonCreditCard from "../../img/financing/cocoon-credit-card.png";

interface IProps {}

interface IState {
    plan: FinancingPlanMeta | null;
}

export class FinancingModalTriggerBlock extends React.Component<
    IProps,
    IState
> {
    public state: IState = {
        plan: null,
    };

    componentDidMount() {
        const defaultPlan = getDefaultFinancingPlan();
        if (defaultPlan) {
            this.setState({
                plan: defaultPlan,
            });
        }
    }

    private renderTermsContainer() {
        const preApprovalContainerClasses = classNames({
            "pre-approval-home-ad__container": true,
            "pre-approval-home-ad__container--text": true,
            "pre-approval-home-ad__container--three-col":
                this.state.plan?.description,
        });
        if (!this.state.plan?.description) {
            return null;
        }

        const financeLink = urls.pageURL("finance-link");
        const aprInfoURL = `${financeLink}#apr-information`;

        return (
            <div className={preApprovalContainerClasses}>
                <div>
                    <h3>
                        {this.state.plan.description}
                        <sup>
                            <a href={aprInfoURL}>
                                {this.state.plan.superscript}
                                <span className="ada-screenreader-only">
                                    Additional information about Terms and
                                    Conditions
                                </span>
                            </a>
                        </sup>
                    </h3>
                    <p className="caption-medium">
                        with equal monthly payments &amp; minimum purchase of $
                        {format.money(this.state.plan.threshold)}
                    </p>
                </div>
                <div>
                    <FinancingModalTrigger
                        className="button al-PLCC_Modal-fullapp"
                        title="Apply Now"
                        modalType="full-app"
                    >
                        Apply Now
                    </FinancingModalTrigger>
                </div>
            </div>
        );
    }

    render() {
        const preApprovalContainerClasses = classNames({
            "pre-approval-home-ad__container": true,
            "pre-approval-home-ad__container--text": true,
            "pre-approval-home-ad__container--three-col":
                this.state.plan?.description,
        });
        const preApprovalContainerClassesImage = classNames({
            "pre-approval-home-ad__container": true,
            "pre-approval-home-ad__container--image": true,
            "pre-approval-home-ad__container--three-col":
                this.state.plan?.description,
        });
        return (
            <section className="pre-approval-home-ad">
                <h2 className="pre-approval-home-ad__title">
                    BUY NOW, PAY OVER TIME
                </h2>
                <div className="l-capped-width">
                    <div className={preApprovalContainerClasses}>
                        <h3>Check if you pre-qualify</h3>
                        <p className="caption-medium">
                            without affecting your credit score
                        </p>
                        <div>
                            <FinancingModalTrigger
                                className="button al-PLCC_Modal-prequal"
                                title="Pre-Qualify"
                                modalType="prequal-app"
                            >
                                Check Now
                            </FinancingModalTrigger>
                        </div>
                    </div>
                    <div className={preApprovalContainerClassesImage}>
                        <img
                            className="responsive-img"
                            src={cocoonCreditCard}
                            alt=""
                        />
                        <Link
                            className="link"
                            href={urls.pageURL("finance-link")}
                        >
                            learn more
                        </Link>
                    </div>
                    {this.renderTermsContainer()}
                </div>
            </section>
        );
    }
}
