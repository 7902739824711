import React from "react";
import { connect } from "react-redux";
import { IReview } from "tsi-common-react/src/models/reviews.interfaces";
import {
    TStateMapper,
    TDispatchMapper,
} from "tsi-common-react/src/apps/reducers.interfaces";
import { Dispatchers } from "tsi-common-react/src/apps/reviews/dispatchers";
import { defaultState } from "tsi-common-react/src/apps/reviews/defaults";
import { Review } from "./Review";

interface IOwnProps {}

interface IReduxProps {
    reviews: IReview[];
}

interface IDispatchProps {
    clearFilters: Dispatchers["clearFilters"];
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

const ReviewListComponent = (props: IProps) => {
    if (props.reviews.length === 0) {
        return (
            <div className="reviewlist--no-reviews-found">
                <p>
                    {gettext(
                        "Sorry, we could not find any reviews within your criteria.",
                    )}
                </p>
                <p>
                    <button
                        className="button cta-button"
                        onClick={props.clearFilters}
                    >
                        {gettext("Clear filters and try again?")}
                    </button>
                </p>
            </div>
        );
    }
    return (
        <div className="reviewlist">
            {props.reviews.map((data) => (
                <Review key={`${data.product_id}-${data.id}`} review={data} />
            ))}
        </div>
    );
};

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.reviews || defaultState;
    return {
        reviews: state.data.reviews,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        clearFilters: dispatchers.clearFilters,
    };
};

export const ReviewList = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReviewListComponent);
