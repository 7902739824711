import React from "react";
import Modal from "react-modal";
import classNames from "classnames";
import {
    IProductID,
    IProductAPIURL,
} from "tsi-common-react/src/models/nominals";
import { addProductToBasket } from "tsi-common-react/src/apps/configurator/actions";
import {
    IUpsellModalProps,
    IUpsellModalComponent,
} from "tsi-common-react/src/apps/configurator/models.interfaces";
import { format } from "tsi-common-react/src/utils/format";
import { getDinero } from "tsi-common-react/src/utils/money";

interface IUpsellModalConfig {
    content: {
        imgUrl: string;
        title: string;
        description: string;
        productPageUrl: string;
        topline: string;
        headline: string;
        subline: string;
        overridePrice: number | null;
        valueProps: string[];
        rejectline: string;
    };
    productPid: IProductID;
}

interface IState {
    modalIsOpen: boolean;
    buyIsDisabled: boolean;
}

export class UpsellModal
    extends React.Component<IUpsellModalProps, IState>
    implements IUpsellModalComponent
{
    public state: IState = {
        modalIsOpen: false,
        buyIsDisabled: false,
    };

    private readonly onAddToBasket = async (
        event: React.MouseEvent<HTMLElement>,
        productURL: IProductAPIURL,
    ) => {
        event.preventDefault();
        this.setState({ buyIsDisabled: true });
        try {
            await addProductToBasket(productURL);
        } catch (e) {
            this.setState({ buyIsDisabled: false });
            return;
        }
        this.props.onProceed();
    };

    public openModal(event?: React.MouseEvent<HTMLElement>) {
        if (event) {
            event.preventDefault();
        }
        document.body.setAttribute("style", "overflow-y: hidden");
        this.setState({
            modalIsOpen: true,
        });
    }

    private closeModal(
        event?: React.MouseEvent<Element> | React.KeyboardEvent<Element>,
    ) {
        if (event) {
            event.preventDefault();
        }
        document.body.setAttribute("style", "overflow: initial");
        this.setState({
            modalIsOpen: false,
        });
        this.props.onProceed();
    }

    render() {
        // TODO Fix this hack. Data should get passed in as a prop.
        // Load the CMS data
        const upsellConfig = document.querySelector<HTMLElement>(
            '[data-place-react="modular-configurator"][data-upsell-modal]',
        );
        const cmsData = JSON.parse(
            upsellConfig?.dataset?.upsellModal || "{}",
        ) as IUpsellModalConfig | null;
        if (!cmsData) {
            if (this.state.modalIsOpen) {
                this.props.onProceed();
            }
            return null;
        }
        // Find the correct bundle for this modal
        const bundle = this.props.bundles.find((b) => {
            const suggestedProductParentIDs = b.suggested_products.map(
                (product) => product.parent?.id || product.id,
            );
            return suggestedProductParentIDs.includes(cmsData.productPid);
        });
        if (!bundle) {
            if (this.state.modalIsOpen) {
                this.props.onProceed();
            }
            return null;
        }
        // Find the product to advertise
        const suggestedProduct = bundle.suggested_products.find(
            (product) =>
                cmsData.productPid === (product.parent?.id || product.id),
        );
        if (
            !suggestedProduct ||
            !suggestedProduct.availability.is_available_to_buy
        ) {
            if (this.state.modalIsOpen) {
                this.props.onProceed();
            }
            return null;
        }
        // Build the modal
        const modalStyle: Modal.Styles = {
            overlay: {
                position: "fixed",
                backgroundColor: "rgba(0, 0, 0, 0.64)",
                zIndex: 2000,
            },
            content: {
                padding: "0",
                overflow: "hidden",
                boxShadow: "none",
            },
        };
        const windowWidth = window.innerWidth;
        if (windowWidth < 500) {
            modalStyle.content!.overflow = "scroll";
        }
        const classes = classNames({
            "upsell-modal": windowWidth >= 500,
            "upsell-modal-phone": windowWidth < 500,
        });
        const discountedPrice = cmsData.content.overridePrice
            ? getDinero(suggestedProduct.price.excl_tax).subtract(
                  getDinero(cmsData.content.overridePrice),
              )
            : null;
        return (
            <Modal
                contentLabel="You might also like this product."
                className={classes}
                style={modalStyle}
                isOpen={this.state.modalIsOpen}
                onRequestClose={(e) => {
                    this.closeModal(e);
                }}
            >
                <div
                    className="upsell-modal__close al-upsell-modal__close-button"
                    onClick={(e) => {
                        this.closeModal(e);
                    }}
                ></div>
                <div className="upsell-modal__header">
                    <div
                        className="header-top-line"
                        dangerouslySetInnerHTML={{
                            __html: cmsData.content.topline,
                        }}
                    />
                    <div
                        className="header-headline"
                        dangerouslySetInnerHTML={{
                            __html: cmsData.content.headline,
                        }}
                    />
                    <div
                        className="header-sub-line"
                        dangerouslySetInnerHTML={{
                            __html: cmsData.content.subline,
                        }}
                    />
                </div>
                <div className="upsell-modal__content">
                    <div
                        className="content-image"
                        style={{
                            backgroundImage: `url('${cmsData.content.imgUrl}')`,
                        }}
                    >
                        <div className="content-image__pill">
                            {cmsData.content.valueProps &&
                                cmsData.content.valueProps.map(
                                    (prop, index) => (
                                        <div
                                            key={index}
                                            className="pill-copy"
                                            dangerouslySetInnerHTML={{
                                                __html: prop,
                                            }}
                                        />
                                    ),
                                )}
                        </div>
                    </div>
                    *
                    <div className="content-copy">
                        <div className="content-copy__name">
                            {cmsData.content.title}
                        </div>
                        <div className="content-copy__description">
                            {cmsData.content.description}
                            <a
                                className="content-copy__learn-more al-upsell-modal__learn-more-button"
                                href={`${cmsData.content.productPageUrl}?pid=learnmore_modal`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {gettext("Learn More")}
                            </a>
                        </div>
                        <div className="content-copy__details">
                            {suggestedProduct.attributes.option_size && (
                                <div className="details-size">
                                    Size:{" "}
                                    <span className="details-size__header">
                                        {
                                            suggestedProduct.attributes
                                                .option_size.value
                                        }
                                    </span>
                                </div>
                            )}
                            {discountedPrice ? (
                                <div className="details-price">
                                    <span className="price-override">
                                        {format.money(discountedPrice)}
                                    </span>{" "}
                                    <span className="price-strike">
                                        {format.money(
                                            suggestedProduct.price.excl_tax,
                                        )}
                                    </span>
                                </div>
                            ) : (
                                <div className="details-price">
                                    <span className="price-normal">
                                        {format.money(
                                            suggestedProduct.price.excl_tax,
                                        )}
                                    </span>
                                </div>
                            )}
                        </div>
                        <button
                            className="content-copy__add-to-cart-button button al-upsell-modal__add-to-cart-button"
                            disabled={this.state.buyIsDisabled}
                            onClick={(e) => {
                                this.onAddToBasket(e, suggestedProduct.url);
                            }}
                        >
                            {gettext("Add to Cart")}
                        </button>
                        <a
                            className="content-copy__no-thanks al-upsell-modal__reject-button"
                            onClick={(e) => {
                                this.closeModal(e);
                            }}
                            dangerouslySetInnerHTML={{
                                __html: cmsData.content.rejectline,
                            }}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}
