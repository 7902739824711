import React from "react";
import ReactTooltip from "react-tooltip";
import SVG from "react-inlinesvg";
import iconQuestionMarkCircle from "../../svg/question-mark-circle.svg";

interface IProps {}

interface IState {
    phoneNumber: string;
    basketId: string;
    isSmallScreen: boolean;
    isKeyDown: boolean;
    isTooltipOpen: boolean;
}

export class CartReferenceNumber extends React.Component<IProps, IState> {
    public SMALL_SCREEN_WIDTH_THRESHOLD = 1366;
    private tooltipContainer: HTMLElement | null = null;

    public state: IState = {
        phoneNumber: "",
        basketId: "",
        isSmallScreen: false,
        isKeyDown: false,
        isTooltipOpen: false,
    };

    private readonly checkScreenSize = () => {
        this.setState({
            isSmallScreen: this.isSmallScreenWidth(),
        });
    };

    private readonly afterShowTooltip = () => {
        this.setState({
            isTooltipOpen: true,
        });
    };

    private readonly afterHideTooltip = () => {
        this.setState({
            isTooltipOpen: false,
        });
    };

    componentDidMount() {
        const cartRef = document.querySelector<HTMLElement>(
            ".checkout-header__header-cart-ref",
        );
        if (cartRef) {
            const phoneNumber: string = cartRef.dataset.phoneNumber as string;
            const basketId: string = cartRef.dataset.encodedBasketId as string;

            this.setState({
                phoneNumber: phoneNumber,
                basketId: basketId,
            });
        }

        this.checkScreenSize();
        window.addEventListener("resize", () => {
            this.checkScreenSize();
        });
    }

    componentDidUpdate() {
        const tooltip =
            this.state.isKeyDown &&
            document.querySelector(".basket-lines__tooltip.tooltip");
        if (tooltip) {
            tooltip.classList.add("key-down");
        }
        const activeTooltip = document.querySelector<HTMLElement>(
            ".basket-lines__tooltip.tooltip.show",
        );
        if (activeTooltip) {
            const activeCloseButton = activeTooltip.querySelector("button");
            if (activeCloseButton) {
                activeCloseButton.focus();
            }
        }

        if (!this.state.isTooltipOpen) {
            if (this.tooltipContainer) {
                this.tooltipContainer.classList.add("remove-outline");
            }
        } else {
            if (this.tooltipContainer) {
                this.tooltipContainer.classList.remove("remove-outline");
            }
        }
    }

    private isSmallScreenWidth() {
        return window.innerWidth < this.SMALL_SCREEN_WIDTH_THRESHOLD;
    }

    render() {
        const tipId = "basket-lines__tooltip-cart-ref";
        return (
            <div className="basket-header__header-cart-ref">
                <span className="basket-lines__header-cart-ref--first-line">
                    {gettext("Cart Reference Number: ")}
                </span>
                <span className="basket-lines__header-cart-ref--second-line tooltip-container">
                    <span
                        className="checkout-header__header-cart-ref--bold"
                        aria-label={gettext("Cart Reference Number")}
                    >
                        {this.state.basketId}
                    </span>
                    {/* Automatically activated tooltips should not use <button>s as their triggers
                    so using <div> with tabindex="0" as per a360 aricles
                    https://hub.accessible360.com/kb/articles/524#heading-if-youre-using-automatic-activation- */}
                    <div
                        aria-describedby={tipId}
                        aria-expanded={this.state.isTooltipOpen}
                        className="basket-lines__header-cart-ref-icon-container"
                        data-for={tipId}
                        data-tip={true}
                        ref={(ref) => {
                            this.tooltipContainer = ref;
                        }}
                        tabIndex={0}
                    >
                        <SVG
                            aria-hidden="true"
                            className="basket-lines__header-cart-ref-icon"
                            src={iconQuestionMarkCircle}
                            title={gettext("Questions Icon")}
                        />
                        <span className="ada-screenreader-only">
                            {gettext("Cart Reference Number Explanation")}
                        </span>
                        <ReactTooltip
                            aria-label={gettext("Question about your order?")}
                            class="basket-lines__tooltip tooltip"
                            event={"mouseover focus"}
                            eventOff="scroll mousewheel mouseleave mouseout blur"
                            id={tipId}
                            place={this.state.isSmallScreen ? "left" : "bottom"}
                            role="tooltip"
                            isCapture={true}
                            afterHide={this.afterHideTooltip}
                            afterShow={this.afterShowTooltip}
                        >
                            <strong>
                                {gettext(
                                    "Have an issue completing your order?",
                                )}
                            </strong>
                            <br />
                            {interpolate(
                                gettext(
                                    "Call %s and provide your cart ID for assistance.",
                                ),
                                [this.state.phoneNumber],
                            )}
                        </ReactTooltip>
                    </div>
                </span>
            </div>
        );
    }
}
